import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Closer from "../components/Closer.js"

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  image2,
  title,
  subheading,
  subheading2,
  mainpitch,
}) => {

  return (
    <div>
      <section className="section section--gradient"
        style={{
          backgroundImage: "url(/img/home-hero.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "6em",
          height: "62em",
        }}>
        <div className="container">
          <div className="columns">
            <div className="column is-5 is-offset-1">
              <h2 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen" style={{
                color: "#A1DE1D",
                lineHeight: "1.1em",
                marginBottom: ".5em",
              }}>{title}</h2>
              <p className="home-para">{subheading}</p>
              <br></br>
              <p className="home-para">{subheading2}</p>
              <br></br>
            </div>
            <div className="column is-offset-1">
            </div>
          </div>
        </div>
      </section>
      
      <section className="section section--gradient" style={{
            backgroundImage: "url(/img/topo.svg)",
            backgroundSize: "100%",
            backgroundColor: "#000C29",
            overflow: "visible",
            backgroundRepeat: "no-repeat",
            paddingTop: "5em",
            paddingBottom: "5em",
          }}>
        <div className="container">
            <div className="columns">
              <div className="column is-5 is-offset-1" style={{
                paddingTop:"2em",
                paddingBottom: "5em",
              }}>
                <h2 className="title" style={{
                  color: "#FF6633",
                }}>{mainpitch.title1}</h2>
                <p className="home-para">{mainpitch.description1}</p>
              </div>
              <div className="column has-text-centered is-5">
                <PreviewCompatibleImage imageInfo={mainpitch.image1} />
              </div>
            </div>

            <div className="columns" style={{
                paddingTop:"5em",
                paddingBottom: "1em",
              }}>
              <div className="column is-5 is-offset-1">
                <PreviewCompatibleImage imageInfo={mainpitch.image2} />
              </div>
              <div className="column is-5" >
                <h2 className="title" style={{
                  color: "#30CAFF",
                }}>{mainpitch.title2}</h2>
                <p className="home-para">{mainpitch.description2}</p>
              </div>
            </div>
            <div className="columns" style={{
                paddingTop:"5em",
                paddingBottom: "1em",
              }}>
              <div className="column is-5 is-offset-1" >
                <h2 className="title" style={{
                  color: "#30CAFF",
                }}>{mainpitch.title3}</h2>
                <p className="home-para">{mainpitch.description3}</p>
              </div>
              <div className="column is-offset-1">
              <PreviewCompatibleImage imageInfo={mainpitch.image3} />
              </div>
          </div>
          
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="column" >
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                    </div>
                  </div>
                  <div className="column is-12 has-text-centered" style={{
                paddingTop:"2.5em",
                paddingBottom: "2.5em",
              }}>
                    <h2 className="title" style={{
                      color: "#A1DE1D",
                    }}>{mainpitch.title4}</h2>
                    <p className="home-para">{mainpitch.description4}</p>
                  </div>
                  <div className="column is-12 has-text-centered" style={{
                paddingTop:"2.5em",
                paddingBottom: "2.5em",
              }}>
                    <h2 className="title" style={{
                      color: "#FF6633",
                    }}>{mainpitch.title5}</h2>
                    <p className="home-para">{mainpitch.description5}</p>
                  </div>
                  <div className="column is-12 has-text-centered" style={{
                paddingTop:"2.5em",
                paddingBottom: "2.5em",
              }}>
                    <h2 className="title" style={{
                      color: "#30CAFF",
                    }}>{mainpitch.title6}</h2>
                    <p className="home-para">{mainpitch.description6}</p>
                  </div>
                  <div className="column is-12 has-text-centered" style={{
                paddingTop:"2.5em",
              }}>
                  <PreviewCompatibleImage imageInfo={image2} />
                  </div>

              </div>
            </div>
          </div>


          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="column">
              <div>

                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Closer />
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  main: PropTypes.object,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        image2={frontmatter.image2}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        subheading2={frontmatter.subheading2}
        mainpitch={frontmatter.mainpitch}
        closer={frontmatter.closer}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  mainpitch: PropTypes.shape({
    title1: PropTypes.string,
    description1: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title2: PropTypes.string,
    description2: PropTypes.string,    
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title3: PropTypes.string,
    description3: PropTypes.string,    
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title4: PropTypes.string,
    description4: PropTypes.string,    
    title5: PropTypes.string,
    description5: PropTypes.string,    
    title6: PropTypes.string,
    description6: PropTypes.string,    
  }),
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(width: 750, quality: 100, layout: CONSTRAINED)
          }
        }
        subheading
        subheading2
        mainpitch {
          title1
          description1
          image1 {
            childImageSharp {
              gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          title2
          description2
          image2 {
            childImageSharp {
              gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          title3
          description3
          image3 {
            childImageSharp {
              gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
            }
          }
          title4
          description4
          title5
          description5
          title6
          description6
        }
      }
    }
  }
`;
